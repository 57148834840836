import i18nLang from "../../models/LangInterface";

const Italian:i18nLang={
    Common: {
        Information: "Informazioni",
        DownloadCV: "Scarica il CV",
        Role: "SW Architect / Tech. Leader",
        Status: "Full Time",
        Location: "Luogo",
        BirthDay: "Compleanno",
        Experience: "Esperienza",
        Years: "Anni",
    },
    Credits: {
        Title: "Credits",
        Description: "Com'è stato costruito questo sito?",
        Msg: "Fatto con ❤️ in ",
        Support: "Considera una donazione per aiutare i bambini dell'Ucraina",
        Support2: "Se sei un professionista IT considera di unirti al gruppo",
    },
    Translate: {
        Ok: "Lingua Applicata!",
        Error: "Seleziona una lingua supportata: 'en' or 'it'",
        Missing: "Seleziona almeno una lingua: 'en' or 'it'",
    },
    Experience: {
        Current: "attuale",
        Title: "Work Experience"
    },
    About: {
        Title: "Ciao! Sono Davide 👋",
        Phone: "<Nascosto>",
        Missing: "",
        Help:"Se questa  e' la tua prima visita, e non vuoi giocare un po', scrivi 'help' e premi invio per vedere quello che puoi fare",
        About: "Potrei non essere un Jedi, ma la forza di un tech Lead e un architetto scorre potente in me. \n \nCon oltre 15 anni di esperienza nella programmazione, ho le competenze per progettare e guidare un team di sviluppatori nell'ideazione di soluzioni innovative che ti faranno dire \"non è una luna, è una stazione spaziale\". \n \nSono appassionato di coding e adoro creare software che non solo sia funzionale, ma anche elegante e manutenibile. Sono un convinto sostenitore della scrittura di codice leggibile e mi sforzo sempre di ottenere un codice di facile comprensione. Nel mio tempo libero, mi puoi trovare a nerdare su Star Wars e a giocare ai videogiochi. \n \nChe la forza sia con te e con il tuo codice."
    },
    Commands: {
        whoami: "A Proposito di me",
        credits: "Credits",
        skills: "Skill",
        cv: "Esperienza",
        clear: "Pulisci",
        skill: "Skills",
        dump: "Tutto",
        help:"Aiuto",
        
    },
    Skills: {
        Title: "Competenze & Interessi",
        Descr: "I miei interessi e le mie competenze... mi saro' sicuramente dimenticato qualcosa",
        Certifications: "Le mie Certificazioni",
        Dev: "Sviluppo",
        Arch: "Architettura",
        NoSql: "NoSql",
        Db: "Database",
        Misc: "Altri interessi",
        CiCd: "CI / CD",
        Cloud: "Cloud",
        Mobile: "Sviluppo Mobile"
    },
    Help: {
        Title: "DavideMaggi.dev Help System",
        Intro:"Come usare questo sito",
        Description: "Questa e' la mia pagina personale, non e' il classico sito, quindi e' normale chiedere aiuto ",
        Type:"Scrivi",
        AvailableCmd:"COMANDI DISPONIBILI",
    Topic:"ARGOMENTO",
    DescriptionTitle:"DESCRIZIONE",
        Commands: {
            cmdAbout: "i | im | whoami | about | info",
            cmdAboutDescription: "per mostrare le mie informazioni.",
            cmdCV: "e | cv | exp | experience | job | work",
            cmdCVDescription: "per vedere le mie esperienze lavorative.",
            cmdCredits: "dev | credits",
            cmdCreditsDescription: "per scoprire come ho svluppato questo sito.",
            cmdSkills: "c | s | skill | skills | cert | certs | certifications",
            cmdSkillDescription: "per scoprire le mie competenze ed i miei interessi.",
            cmdTranslate: "tr | translate <it | en>",
            cmdTranslateDescription: "per tradurre i testi nella lingua desidarata.",
            cmdDump: "dump",
            cmdDumpDescription:  "per vedere tutto in una botta sola.",
            cmdClear: "clear | cls",
            cmdClearDescription:  "per svuotare la console.",
            cmdHelp: "h | help | menu",
            cmdHelpDescription:  "per ri-aprire questo aiuto",
            cmdShow: "show | menu",
            cmdShowDescription:  "per mostrare/nascondere i pulsanti di navigazione",
            cmdVersion: "v | version.",
            cmdVersionDescription:  "per mostrare la versione.",
            
            
        }
    }
}


export default Italian